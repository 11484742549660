import Main from '@/app/components/Home'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import useUpdateHead from '@/app/hooks/useUpdateHead'
import { HomeProps } from '@/app/interface/pages'
import { fetchBanners } from '@/app/libs/getData'
import { pages } from '@/app/utils/pages'

export default function HomePage({ setChatbotOpen, setIsConditionsModalOpen, setConditionsContent, bannerImages, bannerImagesMobile, bannerImagesTablet, calls, links, title, description }: HomeProps) {
  useUpdateHead(title, description)

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>

      <Main
        bannerImages={bannerImages}
        bannerImagesMobile={bannerImagesMobile}
        bannerImagesTablet={bannerImagesTablet}
        calls={calls}
        links={links}
        setChatbotOpen={setChatbotOpen}
        setIsConditionsModalOpen={setIsConditionsModalOpen}
        setConditionsContent={setConditionsContent}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const metadata: any = pages.find((page: any) => page.path === "/" && page.category === "root")
    const [banners] = await Promise.all([fetchBanners()])

    // Filtra as chamadas que contêm '/consorcio'
    const filteredIndexes = banners.calls
      .map((call: string, index: number) => call === '/' ? index : -1)
      .filter((index: number) => index !== -1)

    // Filtra `contents` e `defenseTypes` com base nos índices filtrados
    const filteredContents = filteredIndexes.map(index => banners.contents[index])
    const filteredDefenseTypes = filteredIndexes.map(index => banners.defenseTypes[index])
    const filteredBannerImages = filteredIndexes.map(index => banners.bannerImages[index]).filter(banner => banner)
    const filteredBannerImagesTablet = filteredIndexes.map(index => banners.bannerImagesTablet[index]).filter(banner => banner)
    const filteredBannerImagesMobile = filteredIndexes.map(index => banners.bannerImagesMobile[index]).filter(banner => banner)
    const filteredLinks = filteredIndexes.map(index => banners.links[index]).filter(link => link)

    return {
      props: {
        title: metadata.title,
        description: metadata.description,
        contents: filteredContents,
        defenseTypes: filteredDefenseTypes,
        bannerImages: filteredBannerImages,
        bannerImagesTablet: filteredBannerImagesTablet,
        bannerImagesMobile: filteredBannerImagesMobile,
        calls: banners.calls || [],
        links: filteredLinks || []
      }
    }

  } catch (error) {
    console.error('Erro ao buscar metadados ou banners:', error)
    return {
      props: {
        title: '',
        description: '',
        contents: [],
        defenseTypes: [],
        bannerImages: [],
        bannerImagesTablet: [],
        bannerImagesMobile: [],
        calls: [],
        links: []
      }
    }
  }
}
