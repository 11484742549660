import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import BannerSlide from '@/app/components/UI/BannerSlide'
import { BannerSectionProps } from '@/app/interface/components'

const BannerSection: React.FC<BannerSectionProps> = ({ setIsConditionsModalOpen, setConditionsContent, setChatbotOpen, bannerImagesMobile, bannerImagesTablet, links, calls }) => {
    const isTablet = useMediaQuery({ minWidth: 540 })

    const banners = useMemo(() => {
        if (isTablet) return bannerImagesTablet
        return bannerImagesMobile
    }, [isTablet, bannerImagesTablet, bannerImagesMobile])

    return (
        <section>
            <BannerSlide 
                banners={banners!} 
                links={links} 
                setChatbotOpen={setChatbotOpen}
                width={isTablet ? 1024 : 430}
                height={isTablet ? 1204 : 504}
                setIsConditionsModalOpen={setIsConditionsModalOpen}
                setConditionsContent={setConditionsContent}
            />
        </section>
    )
}

export default React.memo(BannerSection)
