import { VehicleSectionProps } from "@/app/interface/components"
import React from "react"
import CustomLink from "@/app/components/UI/CustomLink"
import styles from '@/styles/desktop/Home/VehicleSection.module.scss'
import ImageComponentHeightFixed from "@/app/components/UI/ImageComponentHeightFixed"

const VehicleSection: React.FC<VehicleSectionProps> = ({ lancamento=false, newModel=false, title, content, car }) => {
    return (
        <section className={styles.main}>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    {newModel && (
                        <span className="newModel">
                            Novo
                        </span>
                    )}
                    
                    {lancamento && (
                        <span className="newModel">
                            Lançamento
                        </span>
                    )}

                    <div className={styles.title}>
                        <h2 className="h2-large">{title}</h2>
                        <p>{content}</p>
                    </div>
                    
                </div>
                <div className={styles.links}>
                    <CustomLink  className="btn-tertiary" href={'/' + (car === 'tan' ? 'tan-2025' : car)}>Saiba mais</CustomLink>
                    <CustomLink  className="btn-tertiary" href={'/test-drive/' + car}>Test drive</CustomLink>
                    <CustomLink  className="btn-tertiary" href={'/solicitar-proposta/' + car}>Solicitar proposta</CustomLink>
                </div>
            </div>
            <ImageComponentHeightFixed
                src={process.env.NEXT_PUBLIC_URL_IMG + '/byd/desktop/home/' + car + '.webp'}
                alt=""
                height={800}
                loading={newModel ? 'eager' : 'lazy'}
            />
        </section>
    )
}

export default React.memo(VehicleSection)
