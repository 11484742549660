import React, { useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { BannerSlideProps } from '@/app/interface/components'
import ImageComponent from './ImageComponent'
import CustomLink from './CustomLink'
import classNames from 'classnames'
import { useBannerContext } from '@/app/contexts/BannerContext'

const BannerSlide: React.FC<BannerSlideProps> = ({ setIsConditionsModalOpen, setConditionsContent, banners, links, setChatbotOpen, width, height, isCMS, isMobileCMS }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const isLoopEnabled = banners.length > 1
    const { contents, defenseTypes } = useBannerContext()

    const handleClick = useCallback(() => setChatbotOpen!(true), [setChatbotOpen])

    const handleSlideChange = useCallback((swiper: { realIndex: React.SetStateAction<number> }) => {
        setActiveIndex(swiper.realIndex)
    }, [])

    const extractDomain = useCallback((url: string): string | null => {
        if (!url) return ''
        try {
            const urlObj = new URL(url)
            return urlObj.hostname
        } catch (error) {
            console.error("URL inválida:", error)
            return null
        }
    }, [])
    
    const extractRelativePath = useCallback((url: string): string => {
        if (!url) return ''
        try {
            const urlObject = new URL(url)
            return urlObject.pathname
        } catch (error) {
            console.error("Invalid URL provided:", error)
            return ''
        }
    }, [])

    return (
        <div className={classNames("bannerSlideContainer", { 'cursor-grabbing': banners.length > 1 })}>
            <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={isLoopEnabled}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                    el: '.custom-pagination',
                    renderCustom: (swiper, current, total) => {
                        return Array.from({ length: total }).map((_, index) => {
                            return `<span class="swiper-pagination-bullet${index + 1 === current ? ' swiper-pagination-bullet-active' : ''}"></span>`
                        }).join('')
                    },
                }}
                onSlideChange={handleSlideChange}
                modules={[Autoplay, Pagination, Navigation]}
                className="bannerSlide"
                style={{aspectRatio: width/height}}
            >
                {banners.map((banner, index) => (
                    banner && (
                        <SwiperSlide key={index}>
                            <ImageComponent
                                src={banner}
                                alt=""
                                width={width}
                                height={height}
                                loading={index === 0 ? 'eager' : 'lazy'}
                            />
                        </SwiperSlide>
                    )
                ))}
            </Swiper>
            <div className="custom-buttons">
                {defenseTypes?.[activeIndex] === "defesa no botão condições e ao fim da página" && <button onClick={() => {
                    setIsConditionsModalOpen(true)
                    setConditionsContent(contents?.[activeIndex])
                }} className='btn-tertiary'>Condições comerciais</button>}
                {isCMS ? (
                    <button type="button" style={isMobileCMS ? {width: 152} : {}} className='btn-tertiary'>Solicitar propostas</button>
                ) : links![activeIndex] && extractDomain(links![activeIndex]) === 'vitoriamotorsbyd.com.br' ? (
                    <CustomLink className='btn-tertiary' href={extractRelativePath(links![activeIndex])! || ''}>Solicitar proposta</CustomLink>
                ) : links![activeIndex] ? (
                    <CustomLink className='btn-tertiary' href={links![activeIndex] || ''} target='_blank'>Saiba mais</CustomLink>
                ) : (
                    <button type="button" className='btn-tertiary' onClick={handleClick}>Solicitar propostas</button>
                )}
            </div>
            <div className="custom-pagination"></div>
        </div>
    )
}

export default React.memo(BannerSlide)
