import { ImageComponentProps } from "@/app/interface/components"
import React from "react"

const ImageComponent: React.FC<ImageComponentProps> = ({ src, width, height, alt, loading }) => {
    return (
      <div style={{ position: 'relative', width: '100%', paddingBottom: `${(height / width) * 100}%` }}>
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 'auto' }}
          loading={loading}
        />
      </div>
    )
}

export default React.memo(ImageComponent)
  