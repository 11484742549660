import { useEffect } from 'react'

function useUpdateHead(title: string, description: string) {
  useEffect(() => {
    if (title) {
      document.title = title
    }

    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription && description) {
      metaDescription.setAttribute('content', description)
    }
  }, [title, description])
}

export default useUpdateHead
