import { HomeProps } from "@/app/interface/components"
import BannerSection from "./Sections/BannerSection"
import React from "react"
import VehicleSection from "./Sections/VehicleSection"
import dynamic from "next/dynamic"

const DynamicHighlightedVehicleSection = dynamic(() => import('./Sections/HighlightedVehicleSection'), { ssr: false })
const DynamicVehicleSection = dynamic(() => import('./Sections/VehicleSection'), { ssr: false })
const DynamicLocationSection = dynamic(() => import('./Sections/LocationSection'), { ssr: false })

const HomeDesktop: React.FC<HomeProps> = ({ setChatbotOpen, bannerImages, setIsConditionsModalOpen, setConditionsContent, calls, links }) => {
    return (
        <main>
            <BannerSection
                setChatbotOpen={setChatbotOpen}
                bannerImages={bannerImages}
                calls={calls}
                links={links}
                setIsConditionsModalOpen={setIsConditionsModalOpen}
                setConditionsContent={setConditionsContent}
            />
            <VehicleSection lancamento={true} title="BYD YUAN PRO" content="SEU PRIMEIRO SUV 100% ELÉTRICO" car="yuan-pro" />
            <DynamicVehicleSection lancamento={true} title={'BYD Song Pro'} content={'Híbrido'} car={'song-pro'} />
            <DynamicVehicleSection lancamento={true} title={'BYD King'} content={'O NOVO REI DAS RUAS'} car={'king'} />
            <DynamicHighlightedVehicleSection />
            <DynamicLocationSection />
        </main>
    )
}

export default React.memo(HomeDesktop)
